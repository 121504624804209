.homeBg {
    background-image: url("../images/bg.webp");
    height: 85%;
    background-size: cover;
    margin-top: 0 !important;
}

.menuBar {
    height: 3.5rem;
    display: flex;
    align-items: center;
    width: 100%;
}

.menuBar .menuItem {
    width: 10%;
    font-size: 0.8em;
    padding: 2.8% 0%;
    position: relative;
    cursor: pointer;
}

.menuBar .menuText {
    font-weight: 200;
    text-align: left;
}

.menuContainer {
    display: flex;
    padding: var(--padding);
    background-color: #0367ab59;
    color: white;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;

}

.socialMediaMenu {
    display: flex;
    width: 10%;
    align-items: center;
    justify-content: space-between
}

.contactUsValues .socialMediaMenu {
    width: 25%;
    margin-top: 2%;
}

.socialMediaMenu div {
    border: 1px solid;
    padding: 5%;
    border-radius: 50%;
    cursor: pointer;
}

.activeMenu {
    height: 5px;
    background-color: white;
    /* width: 0%; */
    transform: scaleX(0);
    position: absolute;
    transition: all 0.2s ease-in-out;
    bottom: -0.2rem;
    z-index: 11;
}

.menuText:hover .activeMenu {
    /* width: 100%; */
    transform: scaleX(1);
    bottom: 3px;
    /* visibility: visible; */
}

.activeMenu span {
    visibility: hidden;
}

.bannerOverlay {

    padding: var(--padding);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 91%;
    width: 68%;
    color: var(--white);
    position: relative;
}

.welcome {
    display: flex;
    width: 100%;
    gap: 1%;
    font-weight: 200;
    font-size: 0.9rem;
    align-items: baseline;
}

.bannerOverlay .tagline {
    font-size: 2.5rem;
    letter-spacing: 0.03rem;
    word-wrap: break-word;
    line-height: 1.7;
}

.bannerOverlay .tagline>span {
    font-size: 4.5rem;
    display: block;
    font-weight: bolder;
    line-height: 1.2;
}

.bannerOverlay .companyDescription {
    font-weight: 100;
    letter-spacing: 0.03rem;
    padding-top: 1%;
}

.bannerButtonSection {
    display: flex;
    column-gap: 3%;
    width: inherit;
    padding-top: 5%;
    /* transition: all 10s ease-in-out; */
}

.bannerButtonSection .btnServices {
    border: 2px solid var(--white);
    padding: 3% 10%;
    cursor: pointer;

}

.bannerButtonSection .btnLearnMore {
    background-color: var(--red);
    padding: 3% 10%;
    cursor: pointer;
    border: 0px solid var(--white);
}

.btnLearnMore:hover {
    background-color: transparent;
    color: var(--white);
    border: 2px solid var(--white);
}

.bannerGoals {
    position: absolute;
    top: 92%;
    width: 100%;
    display: inline-flex;
    /* display: flex;
    justify-content: space-between; */
}

.bannerGoals>.service {
    background-color: var(--red);
    display: flex;
    justify-content: space-between;
    padding: 3% 3%;
    width: 40%;
}

.clientCount,
.shippingCount {
    font-size: 1.7rem;
    font-weight: bold;
}

.clientsDescription {
    line-height: 2.5;
    font-weight: 200;
    font-size: 0.9rem;
}

.country {
    color: black;
    display: flex;
    align-items: center;
    width: 24%;
    justify-content: space-evenly;
}

.country .flag {
    filter: drop-shadow(2px 1px 2px #00000099);
}
.linebreak {
    white-space: pre-line;
  }
.countries {
    display: none;
    /* display: flex; */
    /* align-items: end; */
    width: 60%;
    justify-content: space-evenly;
    margin-top: 3%;
}


.aboutUsContainer {
    margin-top: 12%;
    padding: var(--padding1);
    height: 100%;
    padding-bottom: 5%;
    display: flex;
    justify-content: space-between;
}

.aboutUsImage {
    background-image: url(../images/aboutbg.jpg);
    height: 89%;
    width: 45%;
    background-position: center;
    background-size: cover;
    position: relative;
}

.flightImageLeft {
    left: -22% !important;
    background-position: center !important;
    width: 65% !important;
    background-image: url(../images/truck.jpg) !important;
}

.flightImage {
    position: absolute;
    background-image: url(../images/flight.jpg);
    background-size: cover;
    bottom: 10%;
    top: 16%;
    right: -12%;
    height: 67%;
    width: 54%;
    background-position: top;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 0.8rem solid var(--white);
}

.aboutUsText,
.sectionHead {
    display: flex;
    /* column-gap: 2%; */
    align-items: center;
    font-weight: 500;
    font-size: 1.5rem;
}

.aboutUsText svg,
.sectionHead svg {
    margin-right: 1.5%;
}

.aboutUsText span,
.sectionHead span {
    margin-right: 1%;
}

.aboutUsText span,
.sectionHead span {
    color: var(--red);
}

.aboutUsTextContainer {
    width: 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 1.7;
    font-size: 0.9em;
}

.aboutUsHead,
.sectionMainHead {
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 1.3;
    padding-bottom: 2%;
    text-transform: uppercase;
}

.aboutUsDescription,
.productDescription {
    color: var(--gray);
    position: relative;
}


.features {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 2%;
}

.col1 {
    width: 50%;
}

.feature {
    display: flex;
    align-items: center;
    column-gap: 4%;
    line-height: 2;

}

.feature .red {
    color: var(--red);
}


.productContainer {
    padding: var(--padding1);
    background-color: var(--bg_gray);
    /* height: 100%; */
    padding-top: 5%;
    padding-bottom: 5%;
}

.productList {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 2%;

}

.product {
    /* background-image: url("/automobile.jpg"); */
    background-blend-mode: darken;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    width: 30%;
    margin-top: 2%;
    padding: 4% 0%;
    border-radius: 15px;
    background-color:rgb(111 109 109 / 68%);
    background-blend-mode: multiply;
    align-items: center;
    /* position: relative; */
}

.productName {
    text-transform: uppercase;
    font-size: 1.1rem;
    padding-bottom: 5%;
    width: 100%;
}

.viewMoreBtn {
    width: 35%;
    cursor: pointer;
    padding: 1.5% 5%;
    background-color: var(--red);
    display: flex;
    font-weight: 200;
    justify-content: center;
    font-size: 0.8rem;
}

.contactUsBanner {
    background-image: url("../images/contactBanner.jpeg");
    background-position: center;
    background-size: cover;
    padding: var(--padding1);
    padding-top: 6%;
    padding-bottom: 6%;
    display: flex;
    flex-direction: column;
    color: var(--white);
    background-color:rgb(30 30 30 / 67%);
    background-blend-mode: multiply;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.contactUsDetails {
    font-size: 0.9em;
    font-weight: 300;
    width: 75%;
    line-height: 2;
}

.contactUsBtn {
    margin-top: 2%;
    padding: 1%;
    background-color: var(--red);
    width: 15%;
    cursor: pointer;
    font-weight: 300;
}

.ourServicesContainer {
    padding: var(--padding);
    padding-top: 5%;
    padding-bottom: 5%;
}

.serviceDetails {
    display: flex;
    justify-content: space-between;
}

.serviceTextSection {
    width: 55%;
    line-height: 2;
}

.importExportServices {
    color: var(--gray);
    font-size: 0.9em;
}

.logo img {
    width: 170px;
}

.serviceImageSection {
    background-image: url("../images/aboutbg.jpg");
    background-position: bottom;
    background-size: cover;
    height: 70vh;
    position: relative;
    width: 30%;
}

.subHead {
    padding: 2% 0%;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    column-gap: 2%;
}

.subHead .subHeadIcon {
    color: var(--red);
}

.topServices {
    display: flex;
    width: 100%;
    padding: var(--padding);
    column-gap: 1%;
    line-height: 2;
    border-radius: 10px;
}

.service {
    border-radius: 10px;
    width: 25%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.serviceIcon {
    border: 0.2em solid var(--red);
    padding: 4%;
    border-radius: 50%;
    font-size: 1.3em;
    color: var(--red);
}

.serviceImage {
    height: 200px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-size: cover;
    /* height: 40vh; */
}

.serviceInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8% 5% 2% 5%;
    line-height: 1.7;

}

.serviceName {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.1em;
    color: var(--red);
    padding: 2% 0%;
}

.serviceDescription {
    font-weight: 300;
    font-size: 0.85em;
    text-align: center;
}

.contactUs {
    padding: var(--padding1);
    padding-right: 0%;
    display: flex;
    line-height: 2em;
    margin-top: 5%;
    background-color: var(--black);
    position: relative;
}

.gap {
    margin-bottom: 10%;
}

.contactUsRight {
    width: 65%;
    background-color: var(--bg_gray);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    /* padding: 2%; */
    /* padding: 2% 0%; */
}

.contactUsLeft {
    background-color: var(--black);
    color: var(--white);
    width: 35%;
    padding: 5%;
    position: relative;
}

.conatctUsSubHead {
    color: var(--red);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
}

.followUsHead {
    color: var(--white);
    padding: 10% 0% 0% 0%;
}

.contactUsValues {
    font-weight: 300;
    font-size: 0.9em;
}

.contactUsLeft>div {
    padding: 2% 0%;
}



.line {
    width: 50%;
    height: 0.1px;
    color: gray;
    border: none;
    background-color: #7e7c7cc2;
}

.mobileMenuToggle,
.mobileMenuContainer {
    display: none;
}

.scrollToTop {
    position: fixed;
    bottom: 3%;
    right: 3%;
    color: var(--red);
    background-color: white;
    border-radius: 50%;
    font-size: 2em;
    font-weight: 200;
}