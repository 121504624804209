html,
body,
#root {
  height: 100%;
  width: 100%;

  padding: 0px !important;
  margin: 0px !important;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}
html{
    scroll-behavior: smooth;
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
body,
#root {
  /* overflow-x: hidden; */
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --red: #e10800;
  --gray: #7e7c7c;
  --padding: 0% 15%;

  /* --padding1: 0% 7%; */
  --padding1: 0% 10%;
  --bg_gray: #f4f4f4
}
.displayNone{
  display: none !important;
}
.loader {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  background-color: var(--black);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}