@media only screen and (max-width : 768px) {
    :root {
        --padding: 0% 6% !important;
        --padding1: 0% 7% !important;
    }

    .navBarRight,
    .menuContainer {
        display: none !important;
    }

    .bannerGoals {
        left: 4%;
        right: 0;
        width: auto;
    }

    .bannerOverlay {
        width: 100%;
        height: 100%;
    }

    .bannerOverlay .tagline>span {
        font-size: 2.9rem;
    }

    .bannerOverlay .companyDescription {
        padding-top: 4%;
    }

    .homeBg {
        height: 90%;
        background-position: bottom;
        background-color: rgba(142, 141, 139, 0.5);
        background-blend-mode: multiply;
    }

    .desktopNav {
        height: 10%;
        align-items: center;
    }

    .bannerGoals {
        flex-direction: column;
    }

    .bannerGoals>.service {
        width: 89%;
        padding: 6% 4%;
    }

    .aboutUsTextContainer {
        width: 100%;
    }

    .aboutUsContainer {
        flex-direction: column-reverse;
        margin-top: 25%;
        height: auto;
    }
    .productBanner{
        padding: 15% 0% !important;
        font-size: 1.5em !important;
    }
    .countries {
        width: 80%;
        margin: 4% 0%;
    }

    .country {
        width: 30%;
        flex-direction: column;
    }

    .aboutUsHead,
    .sectionMainHead {
        font-size: 1.8em;
        margin: 2% 0%;
    }

    .aboutUsImage {
        width: 85%;
        height: 400px;
        margin-top: 5%;
    }



    .flightImage {
        right: -18%;
        width: 60%;
    }

    .productList{
        width: 98%;
    }
    .productList,
    .serviceDetails {
        flex-direction: column;
    }

    .product {
        width: 100%;
        margin-top: 5%;
        padding: 12% 0%;
    }

    .product .viewMoreBtn {
        padding: 2% 5%;
        width: 30%;
    }

    .contactUsDetails {
        width: 100%;
        text-align: center;
        font-size: 1em;
    }

    .contactUsBtn {
        width: 30%;
        margin-top: 4%;
        padding: 3% 1%;
    }

    .serviceTextSection {
        width: 100%;
    }

    .features {
        flex-direction: column;
        line-height: 2.5;
    }

    .features .col1,
    .contactUsLeft {
        width: 100%;
    }

    .serviceImageSection {
        display: none;
        width: 80%;
        height: 45vh;
        left: 18%;
        margin: 3% 0%;
    }

    .topServices,
    .contactUs {
        flex-direction: column;
    }

    .topServices .service {
        width: 100%;
        margin-top: 4%;
    }
    .service {
        width: 96% !important;
        margin-top: 4%;
    }

    .contactUsRight {
        /* padding: var(--padding1); */
        width: 93%;
        background-color: none;
    }

    .contactUsRight iframe {
        height: 500px;
        width: 100%;
    }

    .footerContainer {
        flex-direction: column;
    }

    .footerContainer .col,
    .col:first-child {
        width: 100% !important;
        margin-top: 5%;
    }

    .contactInfoValue {
        word-break: break-word;

    }

    .contactIcon {
        font-size: 2em;
        margin-right: 3%;
    }

    .contactInfo {
        margin: 5% 0%;
    }

    .callBtnFooter {
        width: 54% !important;
    }

    .contactUsValues {
        font-size: 0.9em;
    }

    .bannerButtonSection {
        margin-top: 8%;
    }

    .mobileMenuToggle {
        display: flex;
        color: var(--white);
        font-size: 2em;
        -webkit-text-stroke: 1px var(--black);

    }

    .showMenu {
        display: flex !important;
        transform: translateX(0%) !important;
        /* opacity: 1 !important; */
    }

    .mobileMenuContainer {
        background-color: var(--black);
        z-index: 10;
        height: 100dvh;
        width: 100%;
        position: absolute;
        color: var(--white);
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 5%;
        position: fixed;
        overflow: hidden;
        /* opacity: 0; */
        transform: translateX(-100%);

        transition: all 0.7s cubic-bezier(0.55, 0.06, 0.68, 0.19);
    }

    .activeMenu {
        display: none;
    }

    .menuItem {
        line-height: 3;
    }

    .closeIcon {
        position: absolute;
        top: 3%;
        right: 7%;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .productContainerIndividual {
        margin-top: 2%;
    }

    .productIconContainer {
        margin: 3% 0%;
    }

    .productIcon {
        padding: 2% 1% !important;
        
    }
    .subProducts{
        flex-direction: column;
    }
    .singleProductContainer{
        width: 100% !important;
        padding: 18% 5% !important;
    }
.justyfyText{
    /* text-align: justify !important; */
    /* text-align:justify; */
/* word-spacing:-1px; */
}
    /* .productName {
        width: 40%;
    } */
}