.desktopNav {
    width: 100%;
    height: 5rem;
    background-color: var(--black);
    display: flex;
    padding: var(--padding);
    justify-content: space-between;
}

.navBarRight {
    display: flex;
    align-items: center;
    color: var(--white);
    letter-spacing: 0.5px;
    width: 45%;
    justify-content: space-between;
}



.navBarRightItem {
    display: flex;
    align-items: center;
}

.logo {
    color: white;
    display: flex;
    align-items: center;
    height: 100%;
}

.navBarRight .text {
    font-size: 0.7em;
}

.navBarRight .icon {
    color: var(--red);
    width: 10%;
    width: 1.5em;
}